import { Datepicker, TextInput } from '@sayrhino/rhino-shared-js';
import Button from 'components/v2/button';
import Input from 'components/v2/input';
import DateInput from 'components/common/form/date-picker';
import React, { ChangeEvent, Component } from 'react';
import { csrfToken } from 'utils/document';
import { put } from 'utils/request';
import './types';
import moment from 'moment';

interface IProps {
  user: IUser;
  users_account_details_path: string;
  updateIsComplete?: () => void;
  updateUser?: (user) => void;
}

interface IState {
  buttonText: string;
  errors: IErrors;
  firstName: string;
  lastName: string;
  phone: string;
  birthdate?: string;
  success: boolean;
  isLoading: boolean;
  email: string;
  isAdmin: boolean;
}

interface IErrors {
  first_name?: [string];
  last_name?: [string];
  birthdate?: [string];
  phone?: [string];
  email?: [string];
}

class UpdatePersonalDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      buttonText: 'Update',
      success: false,
      isLoading: false,
      errors: {},
      firstName: props.user.first_name ? props.user.first_name : '',
      lastName: props.user.last_name ? props.user.last_name : '',
      birthdate: props.user.birthdate ? props.user.birthdate.toString() : '',
      phone: props.user.phone ? props.user.phone : '',
      email: props.user.email ? props.user.email : '',
      isAdmin: props.user.is_admin ? props.user.is_admin : false
    };
  }

  public handleSubmit = () => {
    const authenticity_token = csrfToken();
    const { firstName, lastName, phone, birthdate, email } = this.state;
    const user = {
      first_name: firstName,
      last_name: lastName,
      phone,
      birthdate,
      email
    };

    this.setState({ isLoading: true });
    put(this.props.users_account_details_path, {
      authenticity_token,
      user
    })
      .then((response) => {
        this.setState({ success: true, buttonText: 'Updated' });
        this.setState({ isLoading: false });

        setTimeout(() => {
          this.setState({
            success: false,
            buttonText: 'Update',
            errors: {}
          });
        }, 3000);
      })
      .catch((response: any) => {
        this.setState({ isLoading: false });
        const errors = response.errors;
        this.setState({ errors });
      });
  };

  public handleFirstNameChange = (val: string) => {
    if (this.isEditable()) {
      this.setState({ firstName: val });
    }
  };

  public handleLastNameChange = (val: string) => {
    if (this.isEditable()) {
      this.setState({ lastName: val });
    }
  };

  public isEditable = (): boolean => {
    const { user } = this.props;
    return user.is_admin;
  };

  public handleBirthdateChange = (val: string) => {
    this.setState({ birthdate: val });
  };

  onChangeDate = (date: moment.Moment) => {
    const momentValue = moment(date, 'YYYY-MM-DD');
    const formattedDate = momentValue.isValid() ? momentValue.format('YYYY-MM-DD') : '';
    this.setState({ birthdate: formattedDate });
  };

  public handlePhoneChange = (val: string) => {
    this.setState({ phone: val });
  };

  public handleEmailChange = (val: string) => {
    this.setState({ email: val });
  };

  public handleChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    this.setState({ ...this.state, [key]: event.target.value });
  };

  public render(): JSX.Element {
    const { errors, buttonText, firstName, lastName, birthdate, phone, email } = this.state;

    const editProfile = () => (
      <div className="edit-profile-section">
        <div className="mt-3">
          <TextInput
            id="firstName"
            label="First name"
            value={firstName}
            error={errors?.first_name?.length! > 0}
            onChange={(event) => this.handleChange(event, 'firstName')}
            subtext={errors?.first_name && `First name ${errors?.first_name?.join()}`}
            data-cy="firstName"
          />
          <TextInput
            id="lastName"
            label="Last name"
            value={lastName}
            error={errors?.last_name?.length! > 0}
            onChange={(event) => this.handleChange(event, 'lastName')}
            subtext={errors?.last_name && `Last name ${errors?.last_name?.join()}`}
            data-cy="lastName"
          />
          <div className="form-group">
            <label htmlFor="display_user_birthdate">Date of birth</label>
            <Input
              placeholder="Date of birth"
              value={birthdate}
              className="outline"
              type="date"
              onChangeDate={this.handleBirthdateChange}
              resource="user"
              field="birthdate"
              dataCy="birthdate"
            />
            {errors?.birthdate && <small style={styles.error}>Date of birth {errors?.birthdate.join()}</small>}
          </div>
          <TextInput
            id="phone"
            label="Phone number"
            type="tel"
            value={phone}
            error={errors?.phone?.length! > 0}
            onChange={(event) => this.handleChange(event, 'phone')}
            subtext={errors?.phone && `Phone number ${errors?.phone?.join()}`}
            data-cy="phone"
          />
          <TextInput
            id="email"
            label="Email"
            value={email}
            error={errors?.email?.length! > 0}
            onChange={(event) => this.handleChange(event, 'email')}
            subtext={errors?.email && `Email ${errors?.email.join()}`}
            data-cy="email"
          />
        </div>
        <Button
          text={buttonText}
          styles={styles.button}
          disabled={this.state.isLoading}
          onClick={this.handleSubmit}
          className={this.state.isLoading ? 'my-4 w-100' : 'button-block my-4'}
          dataCy="update"
        />
      </div>
    );

    return (
      <div className="edit-profile-section">
        <div>
          <p className="demi p1"> Update your personal information </p>
          <hr style={styles.divider} />
        </div>
        <div>
          <Input
            placeholder="First name"
            value={firstName}
            onChangeString={this.handleFirstNameChange}
            resource="user"
            field="first_name"
            dataCy="firstName"
          />
          {errors?.first_name && <p style={styles.error}>First name {errors?.first_name.join()}</p>}
          <Input
            placeholder="Last name"
            value={lastName}
            onChangeString={this.handleLastNameChange}
            resource="user"
            field="last_name"
            dataCy="lastName"
          />
          {errors?.last_name && <p style={styles.error}>Last name {errors?.last_name.join()}</p>}
          <Input
            placeholder="Date of birth"
            value={birthdate}
            type="date"
            onChangeDate={this.handleBirthdateChange}
            resource="user"
            field="birthdate"
            dataCy="birthdate"
          />
          {errors?.birthdate && <p style={styles.error}>Date of birth {errors?.birthdate.join()}</p>}
          <Input
            placeholder="Phone number"
            value={phone}
            type="phone"
            onChangeMasked={this.handlePhoneChange}
            resource="user"
            field="phone"
            dataCy="phone"
          />
          {errors?.phone && <p style={styles.error}>Phone number {errors?.phone.join()}</p>}
          <Input
            placeholder="Email"
            value={email}
            type="email"
            onChangeString={this.handleEmailChange}
            resource="user"
            field="email"
            dataCy="email"
          />
          {errors?.email && <p style={styles.error}>Email {errors?.email.join()}</p>}
        </div>
        <Button text={buttonText} styles={styles.button} onClick={this.handleSubmit} dataCy="update" />
      </div>
    );
  }
}

const styles = {
  divider: {
    paddingBottom: '25px'
  },
  button: {
    border: '1px solid #E3E3E3',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'black',
    padding: '0px 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'pointer'
  },
  error: {
    color: 'red'
  }
};

export default UpdatePersonalDetails;
