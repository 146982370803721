import React, { useEffect } from 'react';
import { IChildProps } from './step_wrapper';
import axios from 'axios';
import { csrfToken } from 'utils/document';

const DEFAULT_HEADERS = {
  accept: 'application/json',
  'Content-Type': 'application/json'
};

const DEFAULT_OPTIONS = {
  authenticity_token: csrfToken(),
  credentials: 'same-origin',
  headers: DEFAULT_HEADERS
};

function CdAccountMisconfig(props: IChildProps) {
  useEffect(() => {
    sendSlackMessage();
  }, []);

  const sendSlackMessage = async () => {
    const userId = props.user.result?.id;
    const policyApplicationId = props.policyApplication.result?.id;
    return axios.post(
      `/users/${userId}/policy_applications/${policyApplicationId}/cd_account_misconfig_notification.json`,
      {
        ...DEFAULT_OPTIONS
      }
    );
  };

  return (
    <div className="w-full max-w-3xl self-center flex flex-col gap-6 items-center justify-center px-2 pb-16 text-center text-pitch-neutral-90 font-sans">
      <h1>
        <span className="self-stretch text-center text-pitch-neutral-90 font-sans-extended font-semibold text-pretty text-xl sm:text-2xl md:text-3xl">Cash Deposit payment unavailable due to</span>
        <span className="block self-stretch text-center text-pitch-neutral-90 font-sans-extended font-semibold text-pretty text-xl sm:text-2xl md:text-3xl">account misconfiguration.</span>
      </h1>

      <p>
        Please reach out to your property manager for assistance.
      </p>
    </div>
  );

}

export default CdAccountMisconfig;
