import { IProps as IMapProps } from 'components/v2/signup/map_with_overlay';
import { IResult } from './actions';
import { IRentersInsuranceRequirements } from 'components/v2/renters_insurance/interfaces';
import { IProperty } from 'types/algolia/property';
import { ISubrogationPaymentConfirmationModal } from '../../subro_payment_plans/redux/state';

export interface IPolicyApplication {
  id: number;
  user_id: number;
  first_time_renter: boolean;
  monthly_rent: number;
  yearly_income: number;
  lease_start_date: string;
  lease_end_date: string;
  google_place_id: string;
  authorized_credit_check: boolean;
  has_ssn: boolean;
  lease_guarantor: number;
  discount_code: string;
  security_deposit: boolean;
  employment_status: number;
  citizenship: number;
  recent_address_line_one: string;
  recent_address_line_two: string;
  recent_google_place_id: string;
  address_line_one: string;
  address_line_two: string;
  address_city: string;
  address_state: string;
  zipcode: string;
  phone: string;
  corporation: boolean;
  property_id: number;
  unit_id: number;
  employer_md5: string;
  education_level_slug: string;
  college_md5: string;
  has_guarnator: boolean;
  college_name: string;
  employer_name: string;
  market: string;
  full_address: string;
  full_name: string;
  email: string;
  coverage_start_date?: string;
  coverage_end_date: string;
  coverage_amount_cents: string;
  social_security_number: string;
  cash_deposit_amount_cents?: number | null;
  partial_quote_cash_deposit_amount_cents?: number;
  cash_deposit_selected: boolean;
  cash_deposit_status: any;
  map_props: IMapProps;
  price_info: IPriceInfo;
  submitted: boolean;
  subscribed: boolean;
  partial_quote_id: number;
  partial_quote: boolean;
  custom_coverage_amount_cents: number;
  custom_coverage_multiplier: number;
  signup_step: string;
  assets_range: number;
  source: string;
  policy_term: number;
  stripe_publishable_key: string;
  stripe_payment_intent_client_secret: string;
  stripe_customer_id_exists: boolean | null;
  stripe_microdeposits_hosted_verification_url: string | null;
  renters_insurance_required: boolean;
  renters_insurance_requirements: IRentersInsuranceRequirements;
  partner_eligible_for_renters_insurance: boolean;
  renters_insurance_confirmed: boolean;
  cover_genius_renters_insurance: any;
  living_alone: boolean;
  total_roommates: number;
  total_kids: number;
  partner: boolean;
  other_roommate_relationship: string;
  total_pets: number;
  disqualified_from_monthly: boolean;
  declined_from_subscription: boolean;
  insurance_policy_id: number;
  policy_app_property_info: IProperty;
  policy_app_unit_id: number;
  formatted_coverage_dates: string;
  cash_deposit_only: boolean;
  is_in_progress: boolean;
  is_finalized: boolean;
  is_cash_deposit_paid: boolean;
  is_cash_deposit_pending_check: boolean;
  is_cash_deposit_verifying_bank: boolean;
  is_cash_deposit_payment_failed: boolean;
  is_cash_deposit_payment_processing: boolean;
  is_processing_external_cash_deposit: boolean;
  integration_partner: IIntegrationPartner;
  eligibility: IEligibility;
  application_source: string;
  sdi_selected: boolean;
  from_native_enrollment: boolean;
  partner_id: number;
  partner_enrollment_url?: string;
  uses_partner_enrollment?: boolean;
  selected_product?: typeof CASH_DEPOSIT | typeof SECURITY_DEPOSIT_INSURANCE;
  eligible_for_lemonade_offer: boolean;
  has_effective_lemonade_policy: boolean;
  property_cash_deposit_enabled: boolean;
  property_cd_destination_account_misconfigured: boolean;
}

export const CASH_DEPOSIT = 'cash_deposit';
export const SECURITY_DEPOSIT_INSURANCE = 'security_deposit_insurance';

export interface IUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  corporation: boolean;
  citizenship: number;
  birthdate: string;
  invitation_signup: boolean;
  social_security_number: string;
  password: string;
  password_confirmation: string;
  current_policy_application_id: number;
  employer_md5: string;
  employment_status: number;
  college_md5: string;
  yearly_income: number;
  has_ssn: boolean;
  authorized_credit_check: boolean;
  reset_password_token: string;
  skip_password: boolean;
  sms_marketing_opt_in: string | number;
}

export interface IPriceInfo {
  length: number;
  upfront: string;
  monthly: string;
  upfront_discount?: string;
  coverage_amount: string;
  monthly_cents: number;
}

export type User = Partial<IUser>;
export type PolicyApplication = Partial<IPolicyApplication>;

export interface IModalState {
  visible: boolean;
  redirect?: string;
  displayLoader?: boolean;
}

export interface IInvitation {
  id: number;
  email: string;
  address_line_one: string;
  address_line_two: string;
  building_name: string;
  monthly_rent: number;
  google_place_id: string;
  unit_id: number;
  property_id: number;
  custom_coverage_amount: number;
  coverage_months: string;
  start_date: string;
  end_date: string;
  rental_status: string;
  first_name: string;
  last_name: string;
  owner_name: string;
  phone: string;
  map_props: IMapProps;
  active_property: boolean;
  enrollment_link: string;
}

export interface IRawInvitation {
  id: number;
  email: string;
  address_line_one: string;
  unit_name: string;
  owner_name: string;
  invite_token: string;
}

export type Invitation = Partial<IInvitation>;
export type RawInvitation = Partial<IRawInvitation>;

export interface IState {
  user: IResult<User>;
  policyApplication: IResult<PolicyApplication>;
  modal: IModalState;
  invitation: IResult<Invitation>;
  invitations: Invitation[];
  partnerRentersInsuranceRequirements: IResult<IPartnerRentersInsuranceRequirements>;
  partialQuoteUnitName: IResult<IUnitName>;
  subrogationPaymentConfirmationModal: ISubrogationPaymentConfirmationModal;
  partnerEnrollmentUrl?: string;
}

export interface IPartnerRentersInsuranceRequirements {
  active_renters_insurances_requirements?: IActiveRentersInsuranceRequirements;
  building_name?: string;
}

export interface IUnitName {
  name?: string;
}

export interface IActiveRentersInsuranceRequirements {
  has_exclusive_agreement: boolean | null;
  requires_renters_insurance: boolean | null;
}

export interface IIntegrationPartner {
  id: number;
  name: string;
  enrollment_url: string;
  renter_cash_deposit_portal_url: string;
}

export interface IEligibility {
  eligible: boolean;
  cash_deposit: boolean;
  sdi: boolean;
}
