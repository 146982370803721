/** @jsx jsx */
import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { FONTS, PALETTE, Button as ButtonBase } from '@sayrhino/rhino-shared-js';
import { RhinoLogger } from 'components/v2/shared/rhino_logger';
import Form from 'components/common/form/form';
import RecaptchaWrapper from 'components/common/recaptcha_wrapper';
import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { post } from 'utils/request';
import UiModal from '../edit_account/modal';
import { CardUpdateStep } from '../edit_account/paymentInformation';
import CardSection from './CardSection';
import { AnalyticsMetadataContext } from 'components/v2/utils/AnalyticsMetadataContext';

const postalCodeFlag = (window as any)?.App?.featureFlags?.postalCode;

const SpinnerIcon = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2955 1.62129L21.3626 1.12583L20.3717 0.991463L20.3045 1.48693L19.6948 5.98383C17.8356 3.1619 14.62 1.3541 11 1.3541C5.22386 1.3541 0.5 6.07796 0.5 11.8541C0.5 17.6302 5.22386 22.3541 11 22.3541C16.7761 22.3541 21.5 17.6302 21.5 11.8541V11.3541H20.5V11.8541C20.5 17.078 16.2239 21.3541 11 21.3541C5.77614 21.3541 1.5 17.078 1.5 11.8541C1.5 6.63024 5.77614 2.3541 11 2.3541C14.4035 2.3541 17.3998 4.11497 19.0418 6.82192L14.0661 6.1585L13.5705 6.09241L13.4383 7.08364L13.9339 7.14972L19.6123 7.90685L19.6682 8.02214L19.8427 7.93756L19.9339 7.94972L20.4284 8.01566L20.481 7.62808L20.568 7.58587L20.5045 7.45478L21.2955 1.62129Z"
        fill="#502CB9"
      />
    </svg>
  );
};

const SpinnerIconWrapper = styled.div({
  marginRight: 10
});

const SavePaymentButton = styled(ButtonBase)({
  '&&': {
    justifyContent: 'center',
    margin: 'auto 0px auto 15px',
    '@media (max-width: 768px)': {
      width: '100%',
      margin: '7px 0px'
    },
    '&:disabled': {
      border: `1px solid ${PALETTE.brand4}`,
      backgroundColor: PALETTE.brand4,
      color: PALETTE.brand125,
      fontWeight: 500
    }
  }
});

const CancelButton = styled(ButtonBase)({
  '&&': {
    justifyContent: 'center',
    margin: 'auto 0px auto auto',
    '&&': {
      '@media (max-width: 768px)': {
        width: '100%',
        margin: '7px 0px'
      }
    },
    '&:disabled': {
      border: `1px solid ${PALETTE.neutral12}`,
      fontWeight: 500,
      color: PALETTE.neutral65
    }
  }
});

const Header = styled.h3([FONTS.h3, { color: PALETTE.neutralDark }]);
const Subheader = styled.p([FONTS.p1, { color: PALETTE.neutral65 }]);

const ButtonWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  marginTop: 30
});

interface IProps {
  name: string;
  wrapperClassName: string;
  update_payment_method_url: string;
  stripePublishableKey: string;
  updateStep?: (value: CardUpdateStep) => void;
  closeInitialModal: () => void;
  stripe: IStripeShape;
  onCancel?: any;
  onUpdate?: any;
  isMobile?: any;
  elements: StripeElements | null;
}

interface IState {
  isLoading: boolean;
  updatePaymentMethodClicked: boolean;
  isOpen?: boolean;
  stripeError?: string;
  recaptchaError?: string;
  token?: IStripeToken;
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
  postalCode: string;
  paymentMethod?: IStripePaymentMethod;
  errors: {
    cardNumber?: string;
    postalCode: string;
  };
}

class UpdateCardForm extends Component<IProps, IState> {
  static contextType = AnalyticsMetadataContext;

  public form: any;
  public recaptchaRef: any;

  isMobile = this.props.isMobile;

  constructor(props: IProps) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isLoading: false,
      updatePaymentMethodClicked: false,
      isOpen: false,
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      postalCode: '',
      errors: {
        cardNumber: '',
        postalCode: ''
      }
    };
  }

  public closeModal = () => {
    window.document.getElementById('initialModal')!.parentElement?.setAttribute('style', 'opacity: 1');
    this.setState({ isOpen: false });
  };

  public openModal = () => {
    const { errors, postalCode } = this.state;

    this.validatePostalCode();

    this.setState({ updatePaymentMethodClicked: true });

    this.props.stripe.createToken({ name: this.props.name, address_zip: postalCode }).then((response) => {
      const { error } = response;
      const stripeErrorMessage = error && error.message;
      const stripeErrorCode = error && error.code;

      if (!stripeErrorMessage && !errors.postalCode) {
        window.document.getElementById('initialModal')!.parentElement?.setAttribute('style', 'opacity: 0');
        this.setState({ isOpen: true });
      } else {
        RhinoLogger.error(stripeErrorMessage || '');
        const genericMessage = 'Error on saving payment method. Please, review the data provided and try again';
        const errorMessage = stripeErrorCode === 'card_declined' ? stripeErrorMessage : genericMessage

        this.setState({
          updatePaymentMethodClicked: false,
          errors: { ...errors, cardNumber: errorMessage }
        });
      }
    });
  };

  public cardError = (stripeEvent: IStripeElementEvent) => {
    this.setState({ ...this.state, [stripeEvent.elementType]: stripeEvent.complete });
  };

  public componentDidUpdate = () => {
    const { onUpdate } = this.props;
    const { paymentMethod } = this.state;

    if (paymentMethod) {
      if (onUpdate) {
        post(this.props.update_payment_method_url, { stripePaymentMethod: paymentMethod.id })
          .then((r) => onUpdate(r))
          .catch((r) => onUpdate(r));
      } else {
        this.form.submit();
      }
    }
  };

  public onRecaptchaError = (recaptchaError) => {
    this.setState({ recaptchaError, isLoading: false });
  };

  public handleSubmit = (ev: Event) => {
    ev.preventDefault();
    this.setState({ isLoading: true, recaptchaError: '' });

    this.recaptchaRef.current.reset();
    this.validatePostalCode();
    if (this.state.errors.postalCode) {
      return;
    }
    this.recaptchaRef.current.execute();
  };

  public updateCard = () => {
    this.setState({ isLoading: true, recaptchaError: '' });

    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  };

  public createStripePaymentMethod = () => {
    const { elements } = this.props;
    if (!elements) {
      return;
    }

    const billing_details: { [x: string]: string | object } = { name: this.props.name };

    if (postalCodeFlag) {
      const { postalCode } = this.state;
      billing_details.address = { postal_code: postalCode };
    }

    const cardElement = elements.getElement(CardNumberElement);

    this.props.stripe
      .createPaymentMethod({
        card: cardElement,
        type: 'card',
        billing_details
      })
      .then((response) => {
        const { error, paymentMethod } = response;
        const stripeError = error && error.message;

        this.setState({ stripeError, paymentMethod, isLoading: !stripeError });
      });
  };

  public createStripeToken = () => {
    const { postalCode } = this.state;
    const payload: { [x: string]: string } = { name: this.props.name };

    if (postalCodeFlag) {
      payload.address_zip = postalCode;
    }

    this.props.stripe.createToken(payload).then((response) => {
      const { error, token } = response;
      const stripeError = error && error.message;

      this.setState({ stripeError, token, isLoading: !stripeError });
    });
  };

  public onPostalCodeFieldChange = (e) => {
    this.setState({ postalCode: e.target.value }, () => this.validatePostalCode());
  };

  public render(): JSX.Element {
    const { isLoading, updatePaymentMethodClicked, token, paymentMethod, recaptchaError, postalCode, errors } =
      this.state;
    const { onCancel, update_payment_method_url } = this.props;
    const submitText = isLoading ? 'Saving...' : 'Update Credit Card';
    const submitTextV2 = isLoading ? 'Processing...' : 'Yes, update payment';
    const cancelBtnProps = onCancel ? { onClick: onCancel } : { href: '/users/edit' };
    const savePaymentMethodText = updatePaymentMethodClicked ? 'Processing...' : 'Save payment method';

    const cancelButton = (
      <CancelButton onClick={() => this.props.closeInitialModal()} variant="secondary" data-cy="cancel">
        Cancel
      </CancelButton>
    );

    return (
      <Form
        setRef={(form) => {
          if (form) {
            this.form = form;
          }
        }}
        id="update-card-form"
        action={update_payment_method_url}
        className="form"
        holderClass=""
        onSubmit={this.handleSubmit}
      >
        <CardSection
          autoFocus={false}
          cardError={this.cardError}
          postalCode={postalCode}
          postalCodeError={errors.postalCode}
          cardNumberError={errors.cardNumber}
          onPostalCodeFieldChange={this.onPostalCodeFieldChange}
        />

        {token && <input type="hidden" name="stripeToken" value={token.id} readOnly={true} />}
        {paymentMethod && <input type="hidden" name="stripePaymentMethod" value={paymentMethod.id} readOnly={true} />}

        <div id="accounts-form--cc-save-row" className="col-md-12 col-sm-12 col-xs-12 px-0">
          <div className="col-12" style={styles.recaptcha}>
            {recaptchaError && <p className="error--default">{recaptchaError}</p>}
            <RecaptchaWrapper
              onSuccess={this.createStripePaymentMethod}
              onError={this.onRecaptchaError}
              recaptchaRef={this.recaptchaRef}
            />
          </div>
        </div>
        <ButtonWrapper>
          {!this.isMobile && cancelButton}
          <SavePaymentButton
            variant="primary"
            onClick={this.openModal}
            disabled={updatePaymentMethodClicked}
            data-cy="savePayment"
          >
            {/* if not mobile then margin left is 15px */}
            {updatePaymentMethodClicked && (
              <SpinnerIconWrapper>
                <SpinnerIcon />
              </SpinnerIconWrapper>
            )}
            {savePaymentMethodText}
          </SavePaymentButton>
          {this.isMobile && cancelButton}
        </ButtonWrapper>
        <UiModal closeModal={() => !isLoading && this.props.closeInitialModal()} isOpen={this.state.isOpen!}>
          <div
            css={css`
              @media (max-width: 768px) {
                height: 75vh;
                display: flex;
                align-items: center;
              }
            `}
          >
            <div>
              <Header>Are you sure you want to update payment?</Header>
              <Subheader>
                Updating your payment method initiates automatic charge(s) on the new payment method
                for any past due or outstanding balances you may owe.
              </Subheader>
              <SavePaymentButton
                style={{ width: '100%', margin: '7px 0px' }}
                onClick={this.updateCard}
                data-cy="updatePayment"
                disabled={isLoading}
              >
                {isLoading && (
                  <SpinnerIconWrapper>
                    <SpinnerIcon />
                  </SpinnerIconWrapper>
                )}
                {submitTextV2}
              </SavePaymentButton>
              <CancelButton
                style={{ width: '100%', margin: '15px 0px' }}
                onClick={() => {
                  this.props.closeInitialModal();
                }}
                variant="secondary"
                data-cy="updateConfirmation"
                disabled={isLoading}
              >
                No, cancel
              </CancelButton>
            </div>
          </div>
        </UiModal>
      </Form>
    );
  }

  public validatePostalCode() {
    if (!postalCodeFlag) {
      return;
    }
    const { errors, postalCode } = this.state;
    this.setState({ errors: { ...errors, postalCode: '' } });

    if (!postalCode) {
      this.setState({ errors: { ...errors, postalCode: 'Zip code is required.' } });
    } else if (postalCode.length !== 5) {
      this.setState({ errors: { ...errors, postalCode: 'Invalid zip code, must be 5 digits.' } });
    }
  }
}

const styles = {
  actions: {
    marginBottom: '100px'
  },
  button: {
    width: '253px'
  },
  recaptcha: {
    paddingLeft: '0',
    fontFamily: 'MaisonNeueExtendedLight',
    fontSize: '12px',
    letterSpacing: '0.23px'
  },
  defaultButton: {
    border: '1px solid #E3E3E3',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#777777',
    padding: '0px 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'not-allowed',
    width: '100%'
  },
  activeButton: {
    border: 'none',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: 'white',
    padding: '0 24px',
    height: '48px',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    backgroundColor: '#6318CE',
    cursor: 'pointer',
    width: '100%'
  },
  outlineButton: {
    border: '1px solid #6318CE',
    borderSizing: 'border-box',
    borderRadius: '24px',
    fontFamily: 'MaisonNeueExtendedMedium',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#6318CE',
    padding: '0 24px',
    height: '48px',
    background: 'transparent',
    textAlign: 'center' as 'center',
    float: 'right' as 'right',
    outline: 'none',
    cursor: 'pointer',
    width: '100%'
  }
};

export default injectStripe(UpdateCardForm);
