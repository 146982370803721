import React, { useEffect, useState } from 'react';

import env from 'utils/env';
import { previousStepUrl, useLoader } from '../step_helpers';
import { IChildProps } from './step_wrapper';
import { useAxiosDispatcher, checkInvitation, calculatePriceAndCreateOrUpdatePaymentIntent } from '../api';
import { useDispatch } from 'react-redux';
import { checkInvitationSync, IPaymentInfo, IPaymentProps, updatePolicyApplicationSync } from '../redux/actions';
import { usePayAndSubscribe } from '../hooks/usePayAndSubscribe';
import QuoteDisplayVariant from 'components/v2/quote_display/quote_display_variant';
import Declined from './declined';
import { FONTS } from '@sayrhino/rhino-shared-js';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { SECURITY_DEPOSIT_INSURANCE } from '../redux/state';

const QuoteDisplay = QuoteDisplayVariant;

function PaymentPage(props: IChildProps) {
  const dispatchAxios = useAxiosDispatcher();
  const dispatch = useDispatch();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [priceLoading, setPriceLoading] = useState(true);
  const [isDeclinedForSDI, setIsDeclinedForSDI] = useState(false);
  const [isCashDepositPossible, setIsCashDepositPossible] = useState(false);
  const loaderImage = props.loaderImage;
  const polApp = props.policyApplication?.result || {};
  const backUrl = previousStepUrl(props.step, props.user, props.policyApplication);
  const initialLoader = useLoader();
  const userId = props.user.result?.id;
  const policyApplicationId = props.policyApplication.result?.id;
  const subscribeAndPay = usePayAndSubscribe(props.step);
  const [completedProgress, setCompletedProgress] = useState(0);
  const { search } = useLocation();

  const Title = styled.h1([
    FONTS.h3,
    {
      fontFamily: 'MaisonNeueExtendedMedium',
      textAlign: 'center' as 'center',
      width: '50%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center'
    }
  ]);

  const StyledCenteredDiv = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    top: '38vh'
  });

  const progressBar = (bgcolor: string, completedProgressPercent: number) => {
    const StyledDiv = styled.div({
      display: 'flex'
    });

    const StyledFlex = styled.div({
      display: 'flex',
      justifyContent: 'center'
    });

    const StyledContainer = styled.div({
      height: '8px',
      width: '295px',
      backgroundColor: '#e0e0de',
      borderRadius: 50,
      marginTop: '24px'
    });

    const StyledFiller = styled.div({
      height: '100%',
      width: `${completedProgressPercent}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right'
    });

    return (
      <StyledDiv>
        <StyledFlex>
          <StyledContainer>
            <StyledFiller></StyledFiller>
          </StyledContainer>
        </StyledFlex>
      </StyledDiv>
    );
  };

  const displayLoadingScreen = (displaysubject: boolean) => {
    return (
      displaysubject && (
        <div style={styles.loaderSection}>
          <StyledCenteredDiv>
            <Title>Analyzing your information...</Title>
            <div style={{ display: 'flex', justifyContent: 'center' }}>{progressBar('#6B3BF6', completedProgress)}</div>
          </StyledCenteredDiv>
        </div>
      )
    );
  };

  const getRandomProgressInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  async function prepareQuoteDisplay(userId?: number, policyApplicationId?: number) {
    if (!userId || !policyApplicationId) return;
    setCompletedProgress(getRandomProgressInt(25, 80));
    const upfront = new URLSearchParams(search).get('payment_cadence') !== '0';
    const policyApplication =
      await dispatchAxios(calculatePriceAndCreateOrUpdatePaymentIntent)({
        userId,
        policyApplicationId,
        upfront,
        productType: SECURITY_DEPOSIT_INSURANCE
      });
    const declinedForSDI = policyApplication.result?.declined_from_subscription;
    const cashDepositAmount = policyApplication.result?.cash_deposit_amount_cents;
    setCompletedProgress(getRandomProgressInt(completedProgress, 80));
    dispatch(updatePolicyApplicationSync(policyApplication));
    setPriceLoading(false);
    setCompletedProgress(100);
    if (policyApplication.result) {
      if (declinedForSDI) setIsDeclinedForSDI(true);
      if (cashDepositAmount) setIsCashDepositPossible(true)

      const invitation = await dispatchAxios(checkInvitation)(userId, policyApplicationId);
      if (invitation.result) {
        dispatch(checkInvitationSync(invitation));
      } else {
        setSubmitEnabled(true);
      }
    }
  }

  async function completeApplication(
    userId: number | undefined,
    policyApplicationId: number | undefined,
    paymentProps: IPaymentProps
  ) {
    if (!userId || !policyApplicationId)
      throw Error(
        `Cannot complete policy_app without user & policy_app ids: ${userId}, ${policyApplicationId}`
      );
    const payload: IPaymentInfo = {
      userId,
      policyApplicationId,
      paymentProps
    };
    subscribeAndPay.mutate(payload);
  }

  const displayQuote = () => {
    return (
      <div className="input-holder">
        <QuoteDisplay
          price={{
            monthly: polApp.price_info ? polApp.price_info.monthly : '',
            upfront: polApp.price_info ? polApp.price_info.upfront : '',
            upfront_discount: polApp.price_info?.upfront_discount
          }}
          map={{
            location: polApp.full_address ? polApp.full_address : '',
            size: {
              width: 624,
              height: 144
            },
            apiKey: env('GOOGLE_STATIC_MAPS_API_KEY')
          }}
          address={{
            street_address: polApp.address_line_one ? polApp.address_line_one : '',
            city: polApp.address_city ? polApp.address_city : '',
            state: polApp.address_state ? polApp.address_state : '',
            zip: polApp.zipcode ? polApp.zipcode : '',
            unit: polApp.address_line_two ? polApp.address_line_two : ''
          }}
          user={props.user}
          coverage={{
            start_date: polApp.coverage_start_date ? polApp.coverage_start_date : '',
            end_date: polApp.coverage_end_date ? polApp.coverage_end_date : '',
            amount: polApp.price_info ? polApp.price_info.coverage_amount : '',
            policy_term: polApp.policy_term
          }}
          lease={{
            start_date: polApp.lease_start_date ? polApp.lease_start_date : '',
            end_date: polApp.lease_end_date ? polApp.lease_end_date : ''
          }}
          payment={{
            paths: {
              taa_path: '/tenant_agreement',
              subscriptions_path: '/subscriptions', // can possibly remove
              terms_of_service_path: '/terms_of_service',
              electronic_transactions_agreement_path: '/electronic_transactions_agreement'
            },
            full_name: polApp.full_name ? polApp.full_name : '',
            loaderImage,
            submitEnabled,
            setSubmitEnabled
          }}
          edit_path={backUrl}
          payAndSubscribe={(payload) => completeApplication(userId, policyApplicationId, payload)}
          policyApplication={props.policyApplication}
          copy={props.copy}
          partnerRentersInsuranceRequirements={props.partnerRentersInsuranceRequirements}
        />
      </div>
    );
  };

  useEffect(() => {
    if (userId && policyApplicationId) {
      setSubmitEnabled(false);
      prepareQuoteDisplay(userId, policyApplicationId);
    }
  }, [userId, policyApplicationId]);

  return (
    <div>
      {displayLoadingScreen(initialLoader)}
      {isDeclinedForSDI && !isCashDepositPossible ? <Declined /> : displayQuote()}
    </div>
  );
}

const styles = {
  formHolder: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  inputContainer: {
    paddingTop: '15px'
  },
  loaderSection: {
    zIndex: 1001,
    width: '100%',
    height: '100%',
    position: 'fixed' as 'fixed',
    top: '80px',
    left: '0',
    background: 'white'
  },
  loaderTitle: {
    textAlign: 'center' as 'center',
    width: '50%',
    margin: 'auto',
    marginTop: '20%'
  }
};

export default PaymentPage;
